<script>
import { app } from '@/main'
import { solutionTypeFormatter } from '@/support/utils/solutionTypeFormatter'

const TRANSLATION = {
  applicabilities: {
    solution: app.$t('notifications.mediation-plan:manage.section.associations.datatable.solutions.header.1'),
    learning_path: app.$t('notifications.mediation-plan:manage.section.associations.datatable.learning_path.header.1')
  },

  applicabilitiesLinked: {
    solution: app.$t('notifications.mediation-plan:manage.section.associations.datatable.solutions.header.2'),
    learning_path: app.$t('notifications.mediation-plan:manage.section.associations.datatable.learning_path.header.2')
  },

  search: {
    solution: app.$t('global:form.solution-search'),
    learning_path: app.$t('global:form.track-search'),
    program: app.$t('global:form.program-search')
  }
}

export default {
  name: 'ManageTabAssociation',

  components: {
    Action: () => import('@/components/general/Action'),
    Datatable: () => import('@/components/general/Datatable'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Divisor: () => import('@/components/general/Divisor')
  },

  props: {
    availableApplicabilityList: {
      type: Array,
      default: () => []
    },

    applicability: {
      type: String
    },

    isLoadingMoreApplicabilities: {
      type: Boolean
    }
  },

  data () {
    return {
      formData: {
        associations: []
      }
    }
  },

  computed: {
    getAvailableApplicabilities () {
      const applicabilities = this.availableApplicabilityList
      const associations = this.formData.associations

      const filteredApplicabilities = applicabilities.filter(
        solution => !associations.find(association => association.id === solution.id)
      )

      const sortedApplicabilities = filteredApplicabilities.sort((a, b) => {
        if (a > b) return 1
        if (b > a) return -1

        return 0
      })

      return sortedApplicabilities
    },

    getApplicabilitiesFound () {
      return TRANSLATION.applicabilities[this.applicability]
    },

    getApplicabilitiesLinked () {
      return TRANSLATION.applicabilitiesLinked[this.applicability]
    },

    getApplicabilitySearchText () {
      if (!this.applicability) return ''

      return this.TRANSLATION.search[this.applicability]
    }
  },

  watch: {
    formData: {
      deep: true,
      handler () {
        this.$emit('mergeFormDataTab', this.formData)
      }
    }
  },

  created () {
    this.setup()
  },

  methods: {
    setup () {
      this.TRANSLATION = TRANSLATION
    },

    addToLinkedApplicability (applicabilityId) {
      const applicability = this.availableApplicabilityList.find(applicability => applicability.id === applicabilityId)

      this.formData.associations.push(applicability)
    },

    removeLinkedApplicability (associationId) {
      const filteredAssociations = this.formData.associations.filter(association => association.id !== associationId)

      this.formData.associations = filteredAssociations
    },

    searchApplicability (search) {
      this.$emit('searchApplicability', search)
    },

    loadMoreApplicabilities () {
      this.$emit('loadMoreApplicabilities')
    },

    getSolutionTypeFormattedName (solutionType) {
      return solutionTypeFormatter(solutionType)
    }
  }
}
</script>

<template>
  <form
    class="form linked-applicability"
    @submit.prevent
  >
    <div class="link-table">
      <div class="linkable-items">
        <Datatable
          :items="getAvailableApplicabilities"
          :is-loading-more="isLoadingMoreApplicabilities"
          hide-horizontal-line
          has-infinite-scroll
          has-fade
          empty-message="no_result"
          @loadMore="loadMoreApplicabilities"
        >
          <template slot="thead">
            <th
              class="th"
              width="300"
            >
              {{ getApplicabilitiesFound }}
            </th>
          </template>

          <template slot="tsearch">
            <FilterListSearch
              slot="search"
              :prepend-label="getApplicabilitySearchText"
              :hide-error-details="true"
              class="filter-search"
              @search="searchApplicability"
            />
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr
              :key="props.item.id"
              @click="addToLinkedApplicability(props.item.id)"
            >
              <td class="td td-full">
                <span class="td-text bolder">{{ props.item.name }}</span>
                <br>
                <span
                  v-if="applicability === 'solution'"
                  class="td-text td-small"
                >{{ getSolutionTypeFormattedName(props.item.solutionType) }} | {{ props.item.workload }}</span>
                <span
                  v-if="applicability === 'learning_path'"
                  class="td-text td-small"
                >
                  {{ props.item.learningPathSolutions }} {{ props.item.learningPathSolutions === 1
                    ? 'Solução'
                    : 'Soluções' }} | {{ props.item.workload ? props.item.workload : '0h' }}
                </span>
              </td>

              <td class="td td-button td-small text-right">
                <Action
                  type="button"
                  icon="keyboard_arrow_right"
                  flat
                />
              </td>
            </tr>
          </template>
        </Datatable>
      </div>

      <Divisor orientation="vertical" />

      <div class="linked-items">
        <Datatable
          drag
          :items="formData.associations"
          hide-horizontal-line
        >
          <template slot="thead">
            <th class="th th-full">
              {{ getApplicabilitiesLinked }}
            </th>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr class="tr-draggable">
              <td class="td td-full">
                <span class="td-text bolder">{{ props.item.name }}</span>
              </td>

              <td class="td td-button td-small text-right">
                <Action
                  class="order-action"
                  flat
                  type="button"
                  icon="close"
                  @click="removeLinkedApplicability(props.item.id)"
                />
              </td>
            </tr>
          </template>
        </Datatable>
      </div>
    </div>
  </form>
</template>

<style lang="scss" src="../assets/scss/ManageTabAssociation.scss"></style>
